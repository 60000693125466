/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
import { __decorate, __metadata, __param } from 'tslib';
import { InjectionToken, Injectable, EventEmitter, HostBinding, Output, Input, TemplateRef, ElementRef, Component, isDevMode, Directive, Optional, Inject, NgZone, Renderer2, ContentChild, forwardRef, NgModule } from '@angular/core';
import { filter, debounceTime, take, auditTime } from 'rxjs/operators';
import { fromEvent, Subscription, BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { PopupService, PopupModule } from '@progress/kendo-angular-popup';
import { validatePackage } from '@progress/kendo-licensing';
import { LocalizationService, L10N_PREFIX, ComponentMessages } from '@progress/kendo-angular-l10n';
import { PreventableEvent, isDocumentAvailable, closest, hasObservers } from '@progress/kendo-angular-common';
import { CommonModule } from '@angular/common';

/**
 * @hidden
 */
const packageMetadata = {
    name: '@progress/kendo-angular-tooltip',
    productName: 'Kendo UI for Angular',
    productCodes: ['KENDOUIANGULAR', 'KENDOUICOMPLETE'],
    publishDate: 1646219971,
    version: '',
    licensingDocsUrl: 'https://www.telerik.com/kendo-angular-ui/my-license/?utm_medium=product&utm_source=kendoangular&utm_campaign=kendo-ui-angular-purchase-license-keys-warning'
};

/**
 * Obsolete. Provide the TooltipSettings class instead.
 *
 * @hidden
 */
const TOOLTIP_SETTINGS = new InjectionToken('kendo-ui-tooltip-settings');
/**
 * Provides a global configuration for the Kendo UI Tooltip. Once injected through
 * the `AppComponent` constructor, the configuration properties can be overridden.
 *
 * @example
 * ```ts-no-run
 * import { TooltipSettings } from '@progress/kendo-angular-tooltip';
 *
 * _@Component({
 *    selector: 'my-app',
 *    template: `
 *      <div kendoTooltip>
 *          <button title="Saves the current document">Save</button>
 *      </div>`,
 *    providers: [{
 *        provide: TooltipSettings,
 *        useFactory: (): TooltipSettings => ({
 *          // Override default values of tooltips if wanted
 *          position: 'right'
 *        })
 *    }]
 * })
 * export class AppComponent { }
 * ```
 */
let TooltipSettings = class TooltipSettings {
};
TooltipSettings = __decorate([
    Injectable()
], TooltipSettings);

/**
 * @hidden
 */
function align(position, offset) {
    let anchorAlign = {};
    let popupAlign = {};
    let popupMargin = {};
    switch (position) {
        case 'top':
            anchorAlign = { horizontal: 'center', vertical: 'top' };
            popupAlign = { horizontal: 'center', vertical: 'bottom' };
            popupMargin = { horizontal: 0, vertical: offset };
            break;
        case 'bottom':
            anchorAlign = { horizontal: 'center', vertical: 'bottom' };
            popupAlign = { horizontal: 'center', vertical: 'top' };
            popupMargin = { horizontal: 0, vertical: offset };
            break;
        case 'right':
            anchorAlign = { horizontal: 'right', vertical: 'center' };
            popupAlign = { horizontal: 'left', vertical: 'center' };
            popupMargin = { horizontal: offset, vertical: 0 };
            break;
        case 'left':
            anchorAlign = { horizontal: 'left', vertical: 'center' };
            popupAlign = { horizontal: 'right', vertical: 'center' };
            popupMargin = { horizontal: offset, vertical: 0 };
            break;
        default: break;
    }
    return {
        anchorAlign,
        popupAlign,
        popupMargin
    };
}
/**
 * @hidden
 */
function collision(inputcollision, position) {
    if (inputcollision) {
        return inputcollision;
    }
    if (position === 'top' || position === 'bottom') {
        return { horizontal: 'fit', vertical: 'flip' };
    }
    return { horizontal: 'flip', vertical: 'fit' };
}
function isDocumentNode(container) {
    return container.nodeType === 9;
}
/**
 * @hidden
 */
function closestBySelector(element, selector) {
    if (element.closest) {
        return element.closest(selector);
    }
    const matches = Element.prototype.matches ?
        (el, sel) => el.matches(sel)
        : (el, sel) => el.msMatchesSelector(sel);
    let node = element;
    while (node && !isDocumentNode(node)) {
        if (matches(node, selector)) {
            return node;
        }
        node = node.parentNode;
    }
}
/**
 * @hidden
 */
function contains(container, child) {
    if (!container) {
        return false;
    }
    if (isDocumentNode(container)) {
        return false;
    }
    if (container.contains) {
        return container.contains(child);
    }
    if (container.compareDocumentPosition) {
        // tslint:disable-next-line
        return !!(container.compareDocumentPosition(child) & Node.DOCUMENT_POSITION_CONTAINED_BY);
    }
}
/**
 * @hidden
 */
const hasParent = (node, parent) => {
    while (node && node !== parent) {
        node = node.parentNode;
    }
    return node;
};
/**
 * @hidden
 */
function getCenterOffset(item, dir, size) {
    const rect = item.getBoundingClientRect();
    return rect[dir] + (rect[size] / 2);
}
/**
 * @hidden
 */
function containsItem(collection, item) {
    return collection.indexOf(item) !== -1;
}

/**
 * @hidden
 */
let TooltipContentComponent = class TooltipContentComponent {
    constructor(content, localizationService) {
        this.content = content;
        this.localizationService = localizationService;
        this.close = new EventEmitter();
        this.tooltipWidth = null;
        this.tooltipHeight = null;
        this.callout = true;
        this.calloutStyles = (position, calloutSize, isFlip) => {
            const styles = {};
            const isVertical = position === 'top' || position === 'bottom';
            const flipDeg = '180deg';
            const zeroDeg = '0deg';
            if (!isFlip) {
                styles.transform = isVertical ? `rotateX(${zeroDeg})` : `rotateY(${zeroDeg})`;
                return styles;
            }
            if (position === 'top') {
                styles.bottom = 'unset';
            }
            else if (position === 'bottom') {
                styles.top = 'unset';
            }
            else if (position === 'left') {
                styles.right = 'unset';
            }
            else if (position === 'right') {
                styles.left = 'unset';
            }
            styles[position] = `${-calloutSize}px`;
            styles.transform = isVertical ? `rotateX(${flipDeg})` : `rotateY(${flipDeg})`;
            return styles;
        };
        this.direction = localizationService.rtl ? 'rtl' : 'ltr';
    }
    get cssClasses() {
        return 'k-widget k-tooltip';
    }
    get className() {
        return this.closable;
    }
    get cssPosition() {
        return 'relative';
    }
    ngOnInit() {
        this.dynamicRTLSubscription = this.localizationService.changes
            .subscribe(({ rtl }) => this.direction = rtl ? 'rtl' : 'ltr');
    }
    ngOnDestroy() {
        if (this.dynamicRTLSubscription) {
            this.dynamicRTLSubscription.unsubscribe();
        }
    }
    get closeButtonTitle() {
        return this.closeTitle || this.localizationService.get('closeTitle');
    }
    calloutPositionClass() {
        return {
            'top': 'k-callout-s',
            'left': 'k-callout-e',
            'bottom': 'k-callout-n',
            'right': 'k-callout-w'
        }[this.position];
    }
    onCloseClick(event) {
        event.preventDefault();
        this.close.emit();
    }
    updateCalloutPosition(position, isFlip) {
        if (!this.callout) {
            return;
        }
        const callout = this.content.nativeElement.querySelector('.k-callout');
        const isVertical = position === 'top' || position === 'bottom';
        const size = isVertical ? 'width' : 'height';
        const dir = isVertical ? 'left' : 'top';
        const offsetProperty = isVertical ? 'marginLeft' : 'marginTop';
        const calloutSize = callout.getBoundingClientRect()[size];
        const anchorCenter = getCenterOffset(this.anchor.nativeElement, dir, size);
        const contentCenter = getCenterOffset(this.content.nativeElement, dir, size);
        const diff = Math.abs(contentCenter - anchorCenter);
        if (diff > 1 || diff === 0 || Math.round(diff) === 0) {
            const newMargin = contentCenter - anchorCenter + (calloutSize / 2);
            callout.style[offsetProperty] = `${-newMargin}px`;
        }
        const calloutStyles = this.calloutStyles(position, calloutSize, isFlip);
        Object.keys(calloutStyles).forEach((style) => {
            callout.style[style] = calloutStyles[style];
        });
    }
};
__decorate([
    HostBinding('attr.dir'),
    __metadata("design:type", String)
], TooltipContentComponent.prototype, "direction", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], TooltipContentComponent.prototype, "close", void 0);
__decorate([
    HostBinding('class'),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], TooltipContentComponent.prototype, "cssClasses", null);
__decorate([
    HostBinding('class.k-tooltip-closable'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], TooltipContentComponent.prototype, "className", null);
__decorate([
    HostBinding('style.position'),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], TooltipContentComponent.prototype, "cssPosition", null);
__decorate([
    HostBinding('style.width.px'),
    Input(),
    __metadata("design:type", Number)
], TooltipContentComponent.prototype, "tooltipWidth", void 0);
__decorate([
    HostBinding('style.height.px'),
    Input(),
    __metadata("design:type", Number)
], TooltipContentComponent.prototype, "tooltipHeight", void 0);
__decorate([
    Input(),
    __metadata("design:type", TemplateRef)
], TooltipContentComponent.prototype, "titleTemplate", void 0);
__decorate([
    Input(),
    __metadata("design:type", ElementRef)
], TooltipContentComponent.prototype, "anchor", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], TooltipContentComponent.prototype, "closable", void 0);
__decorate([
    Input(),
    __metadata("design:type", TemplateRef)
], TooltipContentComponent.prototype, "templateRef", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], TooltipContentComponent.prototype, "templateString", void 0);
TooltipContentComponent = __decorate([
    Component({
        selector: 'kendo-tooltip',
        template: `
        <ng-container kendoTooltipLocalizedMessages
            i18n-closeTitle="kendo.tooltip.closeTitle|The title of the close button"
            closeTitle="Close"
        >
        </ng-container>

        <div class="k-tooltip-content">
            <div class="k-tooltip-title" *ngIf="titleTemplate">
                <ng-template
                    [ngIf]="titleTemplate"
                    [ngTemplateOutlet]="titleTemplate"
                    [ngTemplateOutletContext]="{ $implicit: anchor, anchor: anchor }">
                </ng-template>
            </div>

            <ng-template
                [ngIf]="templateRef"
                [ngTemplateOutlet]="templateRef"
                [ngTemplateOutletContext]="{ $implicit: anchor, anchor: anchor }">
            </ng-template>
            <ng-template
                [ngIf]="templateString">
                {{ templateString }}
            </ng-template>
        </div>

        <div *ngIf="closable" class="k-tooltip-button" (click)="onCloseClick($event)">
            <a href="#" class="k-icon k-i-close" [attr.title]="closeButtonTitle"></a>
        </div>

        <div class="k-callout" *ngIf="callout" [ngClass]="calloutPositionClass()"></div>
    `,
        providers: [
            LocalizationService,
            {
                provide: L10N_PREFIX,
                useValue: 'kendo.tooltip'
            }
        ]
    }),
    __metadata("design:paramtypes", [ElementRef,
        LocalizationService])
], TooltipContentComponent);

/**
 * Represents the [Kendo UI Tooltip directive for Angular]({% slug overview_tooltip %}).
 * Used to display additional information that is related to an element.
 *
 * @example
 * ```ts-no-run
 * <div kendoTooltip>
 *    <a title="Tooltip title" href="foo">foo</a>
 * </div>
 * ```
 */
let TooltipDirective = class TooltipDirective {
    constructor(tooltipWrapper, ngZone, renderer, popupService, settings, legacySettings) {
        this.tooltipWrapper = tooltipWrapper;
        this.ngZone = ngZone;
        this.renderer = renderer;
        this.popupService = popupService;
        /**
         * Specifies a selector for elements within a container which will display a tooltip
         * ([see example]({% slug anchorelements_tooltip %})). The possible values include any
         * DOM `selector`. The default value is `[title]`.
         */
        this.filter = '[title]';
        /**
         * Specifies the position of the Tooltip that is relative to the
         * anchor element ([see example]({% slug positioning_tooltip %})).
         *
         * The possible values are:
         * * `top` (default)
         * * `bottom`
         * * `left`
         * * `right`
         */
        this.position = 'top';
        /**
         * Specifies the delay in milliseconds before the Tooltip is shown.
         * * `100` (default) milliseconds.
         */
        this.showAfter = 100;
        /**
         * Specifies if the Тooltip will display a callout arrow.
         *
         * The possible values are:
         * * `true` (default)
         * * `false`
         */
        this.callout = true;
        /**
         * Specifies if the Тooltip will display a **Close** button
         * ([see example]({% slug closable_tooltip %})).
         *
         * The possible values are:
         * * `true`
         * * `false`
         */
        this.closable = false;
        /**
         * Specifies the offset in pixels between the Tooltip and the anchor. Defaults to `6` pixels.
         * If the `callout` property is set to `true`, the offset is rendered from the callout arrow.
         * If the `callout` property is set to `false`, the offset is rendered from the content of the Tooltip.
         */
        this.offset = 6;
        this.anchor = null;
        this.validPositions = ['top', 'bottom', 'right', 'left'];
        this.validShowOptions = ['hover', 'click', 'none'];
        validatePackage(packageMetadata);
        Object.assign(this, settings, legacySettings);
        this.ngZone.runOutsideAngular(() => {
            const wrapper = this.tooltipWrapper.nativeElement;
            this.anchorTitleSubscription = fromEvent(wrapper, 'mouseover')
                .pipe(filter(() => this.filter !== ''))
                .subscribe((e) => {
                const filterElement = closestBySelector(e.target, this.filter);
                if (filterElement) {
                    this.hideElementTitle({ nativeElement: filterElement });
                }
            });
            this.mouseOverSubscription = fromEvent(wrapper, 'mouseover')
                .pipe(debounceTime(100), filter(() => this.filter !== ''))
                .subscribe(e => this.onMouseOver(e));
            this.mouseOutSubscription = fromEvent(wrapper, 'mouseout')
                .pipe(debounceTime(100))
                .subscribe(e => this.onMouseOut(e));
        });
    }
    /**
     * Sets the content of the Tooltip as a template reference
     * ([see example]({% slug templates_tooltip %})).
     */
    set tooltipTemplate(value) {
        this.template = value;
    }
    get tooltipTemplate() { return this.template; }
    /**
     * Shows the Tooltip.
     * @param anchor&mdash; ElementRef|Element.
     * Specifies the element that will be used as an anchor. The Tooltip opens relative to that element.
     */
    show(anchor) {
        if (this.popupRef) {
            return;
        }
        if (anchor instanceof Element) {
            anchor = { nativeElement: anchor };
        }
        this.anchor = anchor;
        if (this.showOn === 'hover') {
            if (this.popupRef) {
                return;
            }
            clearTimeout(this.showTimeout);
            this.showTimeout = window.setTimeout(() => this.showContent(this.anchor), this.showAfter);
        }
        else {
            this.hideElementTitle(this.anchor);
            this.showContent(this.anchor);
        }
    }
    /**
     * Hides the Tooltip.
     */
    hide() {
        clearTimeout(this.showTimeout);
        const anchor = this.anchor && this.anchor.nativeElement;
        if (anchor && anchor.getAttribute('data-title')) {
            if (!anchor.getAttribute('title') && anchor.hasAttribute('title')) {
                anchor.setAttribute('title', anchor.getAttribute('data-title'));
            }
            anchor.setAttribute('data-title', '');
        }
        if (this.popupMouseOutSubscription) {
            this.popupMouseOutSubscription.unsubscribe();
        }
        if (this.closeClickSubscription) {
            this.closeClickSubscription.unsubscribe();
        }
        this.closePopup();
    }
    /**
     * Toggle visibility of the Tooltip.
     *
     * @param anchor&mdash; ElementRef|Element. Specifies the element that will be used as an anchor.
     * @param show&mdash; Optional. Boolean. Specifies if the Tooltip will be rendered.
     */
    toggle(anchor, show) {
        const previousAnchor = this.anchor && this.anchor.nativeElement;
        if (anchor instanceof Element) {
            anchor = { nativeElement: anchor };
        }
        if (previousAnchor !== anchor.nativeElement) {
            this.hide();
        }
        if (previousAnchor === anchor.nativeElement && this.showOn === 'click') {
            this.hide();
        }
        if (typeof show === 'undefined') {
            show = !this.popupRef;
        }
        if (show) {
            this.show(anchor);
        }
        else {
            this.hide();
        }
    }
    ngOnInit() {
        if (this.showOn === undefined) {
            this.showOn = 'hover';
        }
        this.verifyProperties();
    }
    ngOnChanges(changes) {
        if (changes.showOn) {
            this.subscribeClick();
        }
    }
    ngAfterViewChecked() {
        if (!this.popupRef) {
            return;
        }
        if (this.anchor &&
            !hasParent(this.anchor.nativeElement || this.anchor, this.tooltipWrapper.nativeElement)) {
            this.anchor = null;
            this.hide();
        }
    }
    ngOnDestroy() {
        this.hide();
        this.template = null;
        this.anchorTitleSubscription.unsubscribe();
        this.mouseOverSubscription.unsubscribe();
        this.mouseOutSubscription.unsubscribe();
        if (this.mouseClickSubscription) {
            this.mouseClickSubscription.unsubscribe();
        }
        if (this.popupPositionChangeSubscription) {
            this.popupPositionChangeSubscription.unsubscribe();
        }
        if (this.popupMouseOutSubscription) {
            this.popupMouseOutSubscription.unsubscribe();
        }
    }
    showContent(anchorRef) {
        if (!anchorRef.nativeElement.getAttribute('data-title') && !this.template) {
            return;
        }
        this.ngZone.run(() => {
            this.openPopup(anchorRef);
            this.bindContent(this.popupRef.content, anchorRef);
        });
        this.popupRef.popupAnchorViewportLeave
            .pipe(take(1))
            .subscribe(() => this.hide());
    }
    bindContent(contentComponent, anchorRef) {
        const content = contentComponent.instance;
        this.closeClickSubscription = content.close
            .subscribe(() => { this.hide(); });
        if (!this.template) {
            content.templateString = this.anchor.nativeElement.getAttribute('data-title');
        }
        else {
            content.templateRef = this.template;
        }
        if (this.titleTemplate) {
            content.titleTemplate = this.titleTemplate;
        }
        content.closeTitle = this.closeTitle;
        content.anchor = anchorRef;
        content.callout = this.callout;
        content.closable = this.closable;
        content.position = this.position;
        content.tooltipWidth = this.tooltipWidth;
        content.tooltipHeight = this.tooltipHeight;
        this.popupRef.content.changeDetectorRef.detectChanges();
    }
    hideElementTitle(elementRef) {
        const element = elementRef.nativeElement;
        if (element.getAttribute('title')) {
            element.setAttribute('data-title', element.getAttribute('title'));
            element.setAttribute('title', '');
        }
    }
    openPopup(anchorRef) {
        const alignSettings = align(this.position, this.offset);
        const anchorAlign = alignSettings.anchorAlign;
        const popupAlign = alignSettings.popupAlign;
        const popupMargin = alignSettings.popupMargin;
        this.popupRef = this.popupService.open({
            anchor: anchorRef,
            anchorAlign,
            animate: false,
            content: TooltipContentComponent,
            collision: collision(this.collision, this.position),
            margin: popupMargin,
            popupAlign,
            popupClass: 'k-popup-transparent'
        });
        this.renderer.addClass(this.popupRef.popupElement, 'k-tooltip-wrapper');
        if (this.tooltipClass) {
            this.renderer.addClass(this.popupRef.popupElement, this.tooltipClass);
        }
        const popupInstance = this.popupRef.content.instance;
        if (popupInstance.callout) {
            this.popupPositionChangeSubscription = this.popupRef.popupPositionChange
                .subscribe(({ flip }) => {
                const isFlip = flip.horizontal === true || flip.vertical === true;
                popupInstance.updateCalloutPosition(this.position, isFlip);
            });
        }
        if (this.showOn === 'hover') {
            this.ngZone.runOutsideAngular(() => {
                const popup = this.popupRef.popupElement;
                this.popupMouseOutSubscription = fromEvent(popup, 'mouseout')
                    .subscribe((e) => this.onMouseOut(e));
            });
        }
    }
    closePopup() {
        if (this.popupRef) {
            this.popupRef.close();
            this.popupRef = null;
        }
        if (this.popupPositionChangeSubscription) {
            this.popupPositionChangeSubscription.unsubscribe();
        }
    }
    subscribeClick() {
        if (this.mouseClickSubscription) {
            this.mouseClickSubscription.unsubscribe();
        }
        if (this.showOn === 'click') {
            this.mouseClickSubscription = fromEvent(document, 'click')
                .pipe(filter(() => this.filter !== ''))
                .subscribe(e => this.onMouseClick(e, this.tooltipWrapper.nativeElement));
        }
    }
    onMouseClick(e, wrapper) {
        const target = e.target;
        const filterElement = closestBySelector(target, this.filter);
        const popup = this.popupRef && this.popupRef.popupElement;
        if (popup) {
            if (popup.contains(target)) {
                return;
            }
            if (this.closable) {
                return;
            }
        }
        if (wrapper.contains(target) && filterElement) {
            this.toggle(filterElement, true);
        }
        else if (popup) {
            this.hide();
        }
    }
    onMouseOver(e) {
        const filterElement = closestBySelector(e.target, this.filter);
        if (this.showOn !== 'hover') {
            return;
        }
        if (filterElement) {
            this.toggle(filterElement, true);
        }
    }
    onMouseOut(e) {
        if (this.showOn !== 'hover') {
            return;
        }
        if (this.closable) {
            return;
        }
        const popup = this.popupRef && this.popupRef.popupElement;
        const relatedTarget = e.relatedTarget;
        if (relatedTarget && this.anchor && contains(this.anchor.nativeElement, relatedTarget)) {
            return;
        }
        if (relatedTarget && contains(popup, relatedTarget)) {
            return;
        }
        this.hide();
    }
    verifyProperties() {
        if (!isDevMode()) {
            return;
        }
        if (!containsItem(this.validPositions, this.position)) {
            throw new Error(`Invalid value provided for position property.The available options are 'top', 'bottom', 'left', or 'right'.`);
        }
        if (!containsItem(this.validShowOptions, this.showOn)) {
            throw new Error(`Invalid value provided for showOn property.The available options are 'hover' or 'none'.`);
        }
    }
};
__decorate([
    Input(),
    __metadata("design:type", String)
], TooltipDirective.prototype, "filter", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], TooltipDirective.prototype, "position", void 0);
__decorate([
    Input(),
    __metadata("design:type", TemplateRef)
], TooltipDirective.prototype, "titleTemplate", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], TooltipDirective.prototype, "showOn", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], TooltipDirective.prototype, "showAfter", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], TooltipDirective.prototype, "callout", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], TooltipDirective.prototype, "closable", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], TooltipDirective.prototype, "offset", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], TooltipDirective.prototype, "tooltipWidth", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], TooltipDirective.prototype, "tooltipHeight", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], TooltipDirective.prototype, "tooltipClass", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], TooltipDirective.prototype, "collision", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], TooltipDirective.prototype, "closeTitle", void 0);
__decorate([
    Input(),
    __metadata("design:type", TemplateRef),
    __metadata("design:paramtypes", [TemplateRef])
], TooltipDirective.prototype, "tooltipTemplate", null);
TooltipDirective = __decorate([
    Directive({
        selector: '[kendoTooltip]',
        exportAs: 'kendoTooltip'
    }),
    __param(4, Optional()),
    __param(5, Optional()), __param(5, Inject(TOOLTIP_SETTINGS)),
    __metadata("design:paramtypes", [ElementRef,
        NgZone,
        Renderer2,
        PopupService,
        TooltipSettings,
        TooltipSettings])
], TooltipDirective);

/**
 * @hidden
 */
const ERRORS = {
    popover: `Invalid value provided for the 'popover' property. The accepted data types are 'PopoverComponent' or 'PopoverFn'.`,
    templateData: `templateData must be a function, but received`,
    showOn: `Invalid value provided for the 'showOn' property. The available options are 'click', 'hover', 'focus' or 'none'.`
};

/**
 * Represents a template that defines the content of the Popover title.
 *
 * To define the template, nest an `<ng-template>` tag
 * with the `kendoPopoverTitleTemplate` directive inside the `<kendo-popover>` tag.
 */
let PopoverTitleTemplateDirective = class PopoverTitleTemplateDirective {
    constructor(templateRef) {
        this.templateRef = templateRef;
    }
};
PopoverTitleTemplateDirective = __decorate([
    Directive({
        selector: '[kendoPopoverTitleTemplate]'
    }),
    __param(0, Optional()),
    __metadata("design:paramtypes", [TemplateRef])
], PopoverTitleTemplateDirective);

/**
 * Represents a template that defines the content of the Popover body.
 *
 * To define the template, nest an `<ng-template>` tag
 * with the `kendoPopoverBodyTemplate` directive inside the `<kendo-popover>` tag.
 */
let PopoverBodyTemplateDirective = class PopoverBodyTemplateDirective {
    constructor(templateRef) {
        this.templateRef = templateRef;
    }
};
PopoverBodyTemplateDirective = __decorate([
    Directive({
        selector: '[kendoPopoverBodyTemplate]'
    }),
    __param(0, Optional()),
    __metadata("design:paramtypes", [TemplateRef])
], PopoverBodyTemplateDirective);

/**
 * Represents a template that defines the content of the Popover actions.
 *
 * To define the template, nest an `<ng-template>` tag
 * with the `kendoPopoverActionsTemplate` directive inside the `<kendo-popover>` tag.
 */
let PopoverActionsTemplateDirective = class PopoverActionsTemplateDirective {
    constructor(templateRef) {
        this.templateRef = templateRef;
    }
};
PopoverActionsTemplateDirective = __decorate([
    Directive({
        selector: '[kendoPopoverActionsTemplate]'
    }),
    __param(0, Optional()),
    __metadata("design:paramtypes", [TemplateRef])
], PopoverActionsTemplateDirective);

/**
 * Represents the [Kendo UI Popover component for Angular]({% slug overview_popover %}).
 * Used to display additional information that is related to a target element.
 *
 * @example
 * ```ts-no-run
 * <kendo-popover>
 *    <ng-template kendoPopoverTitleTemplate>Foo Title</ng-template>
 *    <ng-template kendoPopoverBodyTemplate>Foo Body</ng-template>
 *    <ng-template kendoPopoverActionsTemplate>Foo Actions</ng-template>
 * </kendo-popover>
 * ```
 */
let PopoverComponent = class PopoverComponent {
    constructor(localization) {
        this.localization = localization;
        /**
         * Specifies the position of the Popover in relation to its anchor element. [See example]({% slug positioning_popover %})
         *
         * The possible options are:
         * `top`
         * `bottom`
         * `right` (Default)
         * `left`
         */
        this.position = 'right';
        /**
         * Determines whether a callout will be rendered along the Popover. [See example]({% slug callout_popover %})
         *
         * @default true
         */
        this.callout = true;
        /**
         * Enables and configures the Popover animation. [See example]({% slug animations_popover %})
         *
         * The possible options are:
         *
         * * `boolean`&mdash;Enables the default animation
         * * `PopoverAnimation`&mdash;A configuration object which allows setting the `direction`, `duration` and `type` of the animation.
         *
         * @default false
         */
        this.animation = false;
        /**
         * @hidden
         * Determines the visibility of the Popover.
         */
        this.visible = false;
        /**
         * Fires before the Popover is about to be shown ([see example]({% slug events_popover %})).
         * The event is preventable. If canceled, the Popover will not be displayed. [See example]({% slug events_popover %})
         */
        this.show = new EventEmitter();
        /**
         * Fires after the Popover has been shown and the animation has ended. [See example]({% slug events_popover %})
         */
        this.shown = new EventEmitter();
        /**
         * Fires when the Popover is about to be hidden ([see example]({% slug events_popover %})).
         * The event is preventable. If canceled, the Popover will remain visible.
         */
        this.hide = new EventEmitter();
        /**
         * Fires after the Popover has been hidden and the animation has ended. [See example]({% slug events_popover %})
         */
        this.hidden = new EventEmitter();
        this._offset = 6;
        this._width = 'auto';
        this._height = 'auto';
        this.subs = new Subscription();
        /**
         * @hidden
         */
        this._templateData = () => null;
        validatePackage(packageMetadata);
    }
    /**
     * Specifies the distance from the Popover to its anchor element in pixels.
     *
     * @default `6`
     */
    set offset(value) {
        this._offset = value;
    }
    get offset() {
        const calloutBuffer = 14;
        return this.callout
            ? calloutBuffer + this._offset
            : this._offset;
    }
    /**
     * Determines the width of the popover. Numeric values are treated as pixels.
     * @default 'auto'
     */
    set width(value) {
        this._width = typeof value === 'number' ? `${value}px` : value;
    }
    get width() {
        return this._width;
    }
    /**
     * Determines the height of the popover. Numeric values are treated as pixels.
     * @default 'auto'
     */
    set height(value) {
        this._height = typeof value === 'number' ? `${value}px` : value;
    }
    get height() {
        return this._height;
    }
    /**
     * Defines a callback function which returns custom data passed to the Popover templates.
     * It exposes the current anchor element as an argument. [See example]({% slug templates_popover %}#toc-popoverdatacallback)
     */
    set templateData(fn) {
        if (isDevMode && typeof fn !== 'function') {
            throw new Error(`${ERRORS.templateData} ${JSON.stringify(fn)}.`);
        }
        this._templateData = fn;
    }
    get templateData() {
        return this._templateData;
    }
    /**
     * @hidden
     */
    get isHidden() {
        return !this.visible;
    }
    /**
     * @hidden
     */
    get hasAttributeHidden() {
        return !this.visible;
    }
    ngOnInit() {
        this.subs.add(this.localization.changes.subscribe(({ rtl }) => {
            this.direction = rtl ? 'rtl' : 'ltr';
        }));
    }
    ngOnDestroy() {
        this.subs.unsubscribe();
    }
    /**
     * @hidden
     */
    getCalloutPosition() {
        switch (this.position) {
            case 'top': return { 'k-callout-s': true };
            case 'bottom': return { 'k-callout-n': true };
            case 'left': return { 'k-callout-e': true };
            case 'right': return { 'k-callout-w': true };
            default: return { 'k-callout-s': true };
        }
    }
};
__decorate([
    Input(),
    __metadata("design:type", String)
], PopoverComponent.prototype, "position", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number),
    __metadata("design:paramtypes", [Number])
], PopoverComponent.prototype, "offset", null);
__decorate([
    HostBinding('style.width'),
    Input(),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [Object])
], PopoverComponent.prototype, "width", null);
__decorate([
    HostBinding('style.height'),
    Input(),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [Object])
], PopoverComponent.prototype, "height", null);
__decorate([
    HostBinding('attr.dir'),
    __metadata("design:type", String)
], PopoverComponent.prototype, "direction", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], PopoverComponent.prototype, "title", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], PopoverComponent.prototype, "subtitle", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], PopoverComponent.prototype, "body", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], PopoverComponent.prototype, "callout", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], PopoverComponent.prototype, "animation", void 0);
__decorate([
    Input(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Function])
], PopoverComponent.prototype, "templateData", null);
__decorate([
    HostBinding('class.k-hidden'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], PopoverComponent.prototype, "isHidden", null);
__decorate([
    HostBinding('attr.aria-hidden'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], PopoverComponent.prototype, "hasAttributeHidden", null);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], PopoverComponent.prototype, "show", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], PopoverComponent.prototype, "shown", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], PopoverComponent.prototype, "hide", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], PopoverComponent.prototype, "hidden", void 0);
__decorate([
    ContentChild(PopoverTitleTemplateDirective, { static: false }),
    __metadata("design:type", PopoverTitleTemplateDirective)
], PopoverComponent.prototype, "titleTemplate", void 0);
__decorate([
    ContentChild(PopoverBodyTemplateDirective, { static: false }),
    __metadata("design:type", PopoverBodyTemplateDirective)
], PopoverComponent.prototype, "bodyTemplate", void 0);
__decorate([
    ContentChild(PopoverActionsTemplateDirective, { static: false }),
    __metadata("design:type", PopoverActionsTemplateDirective)
], PopoverComponent.prototype, "actionsTemplate", void 0);
PopoverComponent = __decorate([
    Component({
        selector: 'kendo-popover',
        providers: [
            LocalizationService,
            {
                provide: L10N_PREFIX,
                useValue: 'kendo.popover'
            }
        ],
        template: `
    <div *ngIf="visible" role="tooltip" class="k-popover" [ngStyle]="{'width.px': width, 'height.px': height}">
        <div class="k-popover-callout" [ngClass]="getCalloutPosition()" *ngIf="callout"></div>

        <div *ngIf="titleTemplate || title" class="k-popover-header">
            <ng-template *ngIf="titleTemplate"
                [ngTemplateOutlet]="titleTemplate?.templateRef"
                [ngTemplateOutletContext]="{ $implicit: anchor, data: contextData }">
            </ng-template>
            <ng-container *ngIf="title && !titleTemplate">
                {{ title }}
            </ng-container>
        </div>

        <div *ngIf="bodyTemplate || body" class="k-popover-body">
            <ng-template *ngIf="bodyTemplate"
                [ngTemplateOutlet]="bodyTemplate?.templateRef"
                [ngTemplateOutletContext]="{ $implicit: anchor, data: contextData }">
            </ng-template>
            <ng-container *ngIf="body && !bodyTemplate">
                {{ body }}
            </ng-container>
        </div>

        <div *ngIf="actionsTemplate" class="k-popover-actions k-actions k-hstack k-justify-content-between">
            <ng-template *ngIf="actionsTemplate"
                [ngTemplateOutlet]="actionsTemplate?.templateRef"
                [ngTemplateOutletContext]="{ $implicit: anchor, data: contextData }">
            </ng-template>
        </div>
    </div>
    `
    }),
    __metadata("design:paramtypes", [LocalizationService])
], PopoverComponent);

/**
 * Arguments for the `show` event. The `show` event fires when a popover is about
 * to be opened. If you cancel the event, the opening is prevented.
 */
class PopoverShowEvent extends PreventableEvent {
    /**
     * @hidden
     * Constructs the event arguments for the `show` event.
     * @param anchor - The host element related to the popover.
     */
    constructor(anchor) {
        super();
        this.anchor = anchor;
    }
}
/**
 * Arguments for the `hide` event. The `hide` event fires when a popover is about
 * to be closed. If you cancel the event, the popover stays open.
 */
class PopoverHideEvent extends PreventableEvent {
    /**
     * @hidden
     * Constructs the event arguments for the `hide` event.
     * @param anchor - The host element related to the popover.
     * @param popover - The popover element.
     */
    constructor(anchor, popover) {
        super();
        this.anchor = anchor;
        this.popover = popover;
    }
}
/**
 * Arguments for the `shown` event. The `shown` event fires after the popover has opened and its opening animation has finished.
 */
class PopoverShownEvent {
    /**
     * @hidden
     * Constructs the event arguments for the `shown` event.
     * @param anchor - The host element related to the popover.
     * @param popover - The popover element.
     */
    constructor(anchor, popover) {
        this.anchor = anchor;
        this.popover = popover;
    }
}
/**
 * Arguments for the `hidden` event. The `hidden` event fires after the popover has closed and its closing animation has finished.
 */
class PopoverHiddenEvent {
    /**
     * @hidden
     * Constructs the event arguments for the `hidden` event.
     * @param anchor - The host element related to the popover.
     */
    constructor(anchor) {
        this.anchor = anchor;
    }
}

const validShowOptions = ['hover', 'click', 'none', 'focus'];
/**
 * @hidden
 */
class PopoverDirectivesBase {
    constructor(ngZone, popupService, renderer) {
        this.ngZone = ngZone;
        this.popupService = popupService;
        this.renderer = renderer;
        this.subs = new Subscription();
        this._showOn = 'click';
    }
    /**
     * Specifies the popover instance that will be rendered.
     * Accepts a [`PopoverComponent`]({% slug api_tooltip_popovercomponent %}) instance or
     * a [`PopoverFn`]({% slug api_tooltip_popoverfn %}) callback which returns a [`PopoverComponent`]({% slug api_tooltip_popovercomponent %}) instance
     * depending on the current anchor element.
     *
     * [See example]({% slug templates_popover %}#toc-popovercallback)
     */
    set popover(value) {
        if (value instanceof PopoverComponent || typeof value === `function`) {
            this._popover = value;
        }
        else {
            if (isDevMode) {
                throw new Error(ERRORS.popover);
            }
        }
    }
    get popover() {
        return this._popover;
    }
    /**
     * The event on which the Popover will be shown
     *
     * The supported values are:
     * - `click` (default) &mdash;The Popover will be shown when its `anchor` element is clicked.
     * - `hover`&mdash;The Popover will be shown when its `anchor` element is hovered.
     * - `focus`&mdash;The Popover will be shown when its `anchor` element is focused.
     * - `none`&mdash;The Popover will not be shown on user interaction. It could be rendered via the Popover API methods.
     */
    set showOn(value) {
        if (isDevMode && !containsItem(validShowOptions, value)) {
            throw new Error(ERRORS.showOn);
        }
        this._showOn = value;
    }
    get showOn() {
        return this._showOn;
    }
    ngAfterViewInit() {
        if (!isDocumentAvailable()) {
            return;
        }
        this.ngZone.runOutsideAngular(() => {
            switch (this.showOn) {
                case 'hover':
                    this.subscribeToEvents([{
                            name: 'mouseenter', handler: this.mouseenterHandler
                        }, {
                            name: 'mouseleave', handler: this.mouseleaveHandler
                        }]);
                    break;
                case 'focus':
                    this.subscribeToEvents([{
                            name: 'focus', handler: this.focusHandler
                        }, {
                            name: 'blur', handler: this.blurHandler
                        }]);
                    break;
                case 'click':
                    this.subscribeClick();
                    break;
                default:
                    break;
            }
        });
    }
    ngOnDestroy() {
        this.closePopup();
        if (this.disposeHoverOverListener) {
            this.disposeHoverOverListener();
        }
        if (this.disposeHoverOutListener) {
            this.disposeHoverOutListener();
        }
        if (this.disposeClickListener) {
            this.disposeClickListener();
        }
        if (this._focusInsideSub) {
            this._focusInsideSub.unsubscribe();
        }
        if (this._hideSub) {
            this._hideSub.unsubscribe();
        }
        if (this.subs) {
            this.subs.unsubscribe();
        }
        if (this._popupOpenSub) {
            this._popupOpenSub.unsubscribe();
        }
        if (this._popupCloseSub) {
            this._popupCloseSub.unsubscribe();
        }
    }
    /**
     * Hides the Popover ([See example]({% slug programmaticcontrol_popover %})).
     */
    hide() {
        this.closePopup();
    }
    /**
     * @hidden
     */
    closePopup() {
        if (this.popupRef) {
            this.popupRef.close();
            this.popupRef = null;
            if (this.disposePopupHoverOutListener) {
                this.disposePopupHoverOutListener();
            }
            if (this.disposePopupHoverInListener) {
                this.disposePopupHoverInListener();
            }
            if (this.disposePopupFocusOutListener) {
                this.disposePopupFocusOutListener();
            }
        }
    }
    /**
     * @hidden
     */
    openPopup(anchor) {
        const _anchor = anchor instanceof ElementRef ? anchor.nativeElement : anchor;
        const popoverComp = this.popover instanceof PopoverComponent ? this.popover : this.popover(_anchor);
        const alignSettings = align(popoverComp.position, popoverComp.offset);
        const anchorAlign = alignSettings.anchorAlign;
        const popupAlign = alignSettings.popupAlign;
        const popupMargin = alignSettings.popupMargin;
        const _animation = popoverComp.animation;
        this.popupRef = this.popupService.open({
            anchor: { nativeElement: _anchor },
            animate: _animation,
            content: PopoverComponent,
            popupAlign,
            anchorAlign,
            margin: popupMargin,
            popupClass: 'k-popup-transparent',
            collision: { horizontal: 'fit', vertical: 'fit' }
        });
        this.applySettings(this.popupRef.content, popoverComp, anchor);
        this.monitorPopup();
        this.initializeCompletionEvents(popoverComp, _anchor);
    }
    /**
     * @hidden
     */
    isPrevented(anchorElement, show) {
        const popoverComp = this.popover instanceof PopoverComponent ? this.popover : this.popover(anchorElement);
        let eventArgs;
        eventArgs = this.initializeEvents(popoverComp, eventArgs, show, anchorElement);
        return eventArgs.isDefaultPrevented();
    }
    /**
     * @hidden
     */
    monitorPopup() {
        if (this.showOn === 'hover') {
            this.ngZone.runOutsideAngular(() => {
                const popup = this.popupRef.popupElement;
                this.disposePopupHoverInListener = this.renderer.listen(popup, 'mouseenter', _ => {
                    this.ngZone.run(_ => this._popoverService.emitPopoverState(true));
                });
                this.disposePopupHoverOutListener = this.renderer.listen(popup, 'mouseleave', _ => {
                    this.ngZone.run(_ => this._popoverService.emitPopoverState(false));
                });
            });
        }
        if (this.showOn === 'focus') {
            this.ngZone.runOutsideAngular(() => {
                const popup = this.popupRef.popupElement;
                this.disposePopupFocusOutListener = this.renderer.listen(popup, 'focusout', (e) => {
                    const isInsidePopover = closest(e.relatedTarget, (node) => node.classList && node.classList.contains('k-popover'));
                    if (!isInsidePopover) {
                        this.ngZone.run(_ => this._popoverService.emitFocusInsidePopover(false));
                    }
                });
            });
        }
    }
    applySettings(contentComponent, popover, anchor) {
        const content = contentComponent.instance;
        const _anchor = anchor instanceof ElementRef ? anchor.nativeElement : anchor;
        content.visible = true;
        content.anchor = _anchor;
        content.position = popover.position;
        content.offset = popover.offset;
        content.width = popover.width;
        content.height = popover.height;
        content.title = popover.title;
        content.body = popover.body;
        content.callout = popover.callout;
        content.animation = popover.animation;
        content.contextData = popover.templateData(_anchor);
        content.titleTemplate = popover.titleTemplate;
        content.bodyTemplate = popover.bodyTemplate;
        content.actionsTemplate = popover.actionsTemplate;
        this.popupRef.content.changeDetectorRef.detectChanges();
    }
    /**
     * @hidden
     */
    initializeEvents(popoverComp, eventArgs, show, anchorElement) {
        if (show) {
            eventArgs = new PopoverShowEvent(anchorElement);
            if (this.shouldEmitEvent(!!this.popupRef, 'show', popoverComp)) {
                this.ngZone.run(() => popoverComp.show.emit(eventArgs));
            }
        }
        else {
            eventArgs = new PopoverHideEvent(anchorElement, this.popupRef);
            if (this.shouldEmitEvent(!!this.popupRef, 'hide', popoverComp)) {
                this.ngZone.run(() => popoverComp.hide.emit(eventArgs));
            }
        }
        return eventArgs;
    }
    initializeCompletionEvents(popoverComp, _anchor) {
        if (this.shouldEmitCompletionEvents('shown', popoverComp)) {
            this.popupRef.popupOpen.subscribe(() => {
                const eventArgs = new PopoverShownEvent(_anchor, this.popupRef);
                popoverComp.shown.emit(eventArgs);
            });
        }
        if (this.shouldEmitCompletionEvents('hidden', popoverComp)) {
            this.popupRef.popupClose.subscribe(() => {
                this.ngZone.run(_ => {
                    const eventArgs = new PopoverHiddenEvent(_anchor);
                    popoverComp.hidden.emit(eventArgs);
                });
            });
        }
    }
    shouldEmitEvent(hasPopup, event, popoverComp) {
        if ((event === 'show' && !hasPopup && hasObservers(popoverComp[event]))
            || (event === 'hide' && hasPopup && hasObservers(popoverComp[event]))) {
            return true;
        }
        return false;
    }
    shouldEmitCompletionEvents(event, popoverComp) {
        if ((hasObservers(popoverComp[event]) && !this._popupOpenSub)
            || (hasObservers(popoverComp[event]) && !this._popupCloseSub)) {
            return true;
        }
        return false;
    }
}
__decorate([
    Input(),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [Object])
], PopoverDirectivesBase.prototype, "popover", null);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], PopoverDirectivesBase.prototype, "showOn", null);

/**
 * @hidden
 */
let PopoverService = class PopoverService {
    constructor(ngZone) {
        this.ngZone = ngZone;
        this._pointerOverPopup = new BehaviorSubject(null);
        this._pointerOverAnchor = new BehaviorSubject(null);
        this._focusInsidePopover = new BehaviorSubject(null);
        this._hidePopover = new Subject();
        this.subs = new Subscription();
        this.monitor();
    }
    ngOnDestroy() {
        this.subs.unsubscribe();
    }
    get isPopoverHovered() {
        return this._pointerOverPopup.asObservable();
    }
    emitPopoverState(isHovered) {
        this.ngZone.run(_ => this._pointerOverPopup.next(isHovered));
    }
    get isAnchorHovered() {
        return this._pointerOverAnchor.asObservable();
    }
    emitAnchorState(isHovered, anchor) {
        this._isOrigin = this.originAnchor === anchor;
        this.currentAnchor = anchor;
        if (isHovered) {
            this.originAnchor = anchor;
        }
        this.ngZone.run(_ => this._pointerOverAnchor.next(isHovered));
    }
    get isFocusInsidePopover() {
        return this._focusInsidePopover.asObservable();
    }
    emitFocusInsidePopover(isFocused) {
        this.ngZone.run(_ => this._focusInsidePopover.next(isFocused));
        this._focusInsidePopover.next(null);
    }
    get hidePopover() {
        return this._hidePopover.asObservable();
    }
    monitor() {
        this.subs.add(combineLatest(this.isPopoverHovered, this.isAnchorHovered).pipe(
        // `auditTime` is used because the `mouseleave` event is emitted before `mouseenter`
        // i.e. there is a millisecond in which the pointer leaves the first target (e.g. anchor) and hasn't reached the second one (e.g. popup)
        // resulting in both observables emitting `false`
        auditTime(20)).subscribe(val => {
            const [isPopoverHovered, isAnchorHovered] = val;
            this._hidePopover.next([isPopoverHovered, isAnchorHovered, this._isOrigin, this.currentAnchor]);
        }));
    }
};
PopoverService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [NgZone])
], PopoverService);

/**
 * Represents the [`kendoPopoverContainer`]({% slug configuration_popover %}#toc-containerdirective) directive.
 * It is used to filter and target multiple elements, which should display a popover on interaction.
 *
 * @example
 * ```ts-no-run
 * <div kendoPopoverContainer [popover]="myPopover" filter=".has-popover">
 *     <button class="has-popover">Show Popover</button>
 *     <button>Button Without Popover</button>
 *     <button class="has-popover">Show Popover</button>
 * </div>
 * ```
 */
let PopoverContainerDirective = class PopoverContainerDirective extends PopoverDirectivesBase {
    constructor(wrapperEl, ngZone, popupService, renderer, popoverService) {
        super(ngZone, popupService, renderer);
        this.wrapperEl = wrapperEl;
        this.ngZone = ngZone;
        this.popupService = popupService;
        this.renderer = renderer;
        this.popoverService = popoverService;
        this.mouseenterHandler = (anchor) => {
            this.controlVisibility(anchor, true);
        };
        this.mouseleaveHandler = (args) => {
            const anchor = args.anchor;
            if (this.isPrevented(anchor, false)) {
                return;
            }
            if (!this._hideSub) {
                this._hideSub = this.popoverService.hidePopover.subscribe((val) => {
                    const [isPopoverHovered, , isOriginAnchor, currentAnchor] = val;
                    if (!isPopoverHovered && !isOriginAnchor) {
                        this.hide();
                        if (!isOriginAnchor && currentAnchor) {
                            this.show(currentAnchor);
                        }
                    }
                });
            }
        };
        this.focusHandler = (anchor) => {
            this.controlVisibility(anchor, true);
        };
        this.blurHandler = (args) => {
            const anchor = args.anchor;
            const event = args.domEvent;
            if (this.isPrevented(anchor, false)) {
                return;
            }
            // from anchor to popup focus check
            const isFocusInside = !!closest(event.relatedTarget, (node) => node.classList && node.classList.contains('k-popover'));
            if (!isFocusInside) {
                this.hide();
            }
            if (!this._focusInsideSub) {
                // inside popup focus check
                this._focusInsideSub = this.popoverService.isFocusInsidePopover.pipe(filter(v => v !== null)).subscribe((val) => {
                    if (!val && !isFocusInside) {
                        this.hide();
                    }
                });
            }
        };
        this._popoverService = this.popoverService;
    }
    /**
     * Shows the Popover.
     *
     * @param anchor&mdash;Specifies the element that will be used as an anchor. The Popover opens relative to that element. [See example]({% slug programmaticcontrol_popover %})
     */
    show(anchor) {
        if (this.popupRef) {
            return;
        }
        this.ngZone.run(() => {
            this.openPopup(anchor);
        });
        this.popupRef.popupAnchorViewportLeave
            .pipe(take(1))
            .subscribe(() => this.hide());
    }
    /**
     * Toggles the visibility of the Popover. [See example]({% slug programmaticcontrol_popover %})
     *
     * @param anchor&mdash;Specifies the element that will be used as an anchor. The Popover opens relative to that element.
     */
    toggle(anchor) {
        const previousAnchor = this.popupRef && this.popupRef.content.instance.anchor;
        if (this.popupRef) {
            this.hide();
            if (previousAnchor !== anchor) {
                this.show(anchor);
            }
        }
        else {
            this.show(anchor);
        }
    }
    subscribeClick() {
        if (this.disposeClickListener) {
            this.disposeClickListener();
        }
        this.disposeClickListener = this.renderer.listen(document, 'click', (e) => {
            const filterElement = closestBySelector(e.target, this.filter);
            this.clickHandler(filterElement, e);
        });
    }
    subscribeToEvents(arr) {
        const filteredElements = Array.from(document.querySelectorAll(this.filter));
        filteredElements.forEach((el) => {
            this.subs.add(this.renderer.listen(el, arr[0].name, () => {
                this.popoverService.emitAnchorState(true, el);
                arr[0].handler(el);
            }));
            this.subs.add(this.renderer.listen(el, arr[1].name, (e) => {
                this.popoverService.emitAnchorState(false, null);
                arr[1].handler({ anchor: el, domEvent: e });
            }));
        });
    }
    clickHandler(anchor, event) {
        const isInsidePopup = !!closest(event.target, (node) => node.classList && node.classList.contains('k-popup'));
        const popupRefAnchor = this.popupRef && this.popupRef.content.instance.anchor;
        let isOriginAnchor = !!closest(event.target, (node) => node === (popupRefAnchor ? popupRefAnchor : anchor));
        if (this.showOn !== 'click' || isInsidePopup || (this.popupRef && isOriginAnchor)) {
            return;
        }
        if (!anchor && this.popupRef) {
            this.controlVisibility(anchor, false);
            return;
        }
        if (isOriginAnchor) {
            this.controlVisibility(anchor, true);
        }
        else if (this.popupRef) {
            this.controlVisibility(anchor, false);
            this.controlVisibility(anchor, true);
        }
    }
    controlVisibility(anchor, show) {
        if (this.isPrevented(anchor, show)) {
            return;
        }
        show ? this.show(anchor) : this.hide();
    }
};
__decorate([
    Input(),
    __metadata("design:type", String)
], PopoverContainerDirective.prototype, "filter", void 0);
PopoverContainerDirective = __decorate([
    Directive({
        selector: '[kendoPopoverContainer]',
        exportAs: 'kendoPopoverContainer',
        providers: [PopoverService]
    }),
    __metadata("design:paramtypes", [ElementRef,
        NgZone,
        PopupService,
        Renderer2,
        PopoverService])
], PopoverContainerDirective);

/**
 * Represents the [`kendoPopoverAnchor`]({% slug configuration_popover %}#toc-anchordirective) directive.
 * It is used to target an element, which should display a popover on interaction.
 *
 * @example
 * ```ts-no-run
 * <button kendoPopoverAnchor [popover]="myPopover">Show Popover</button>
 * ```
 */
let PopoverAnchorDirective = class PopoverAnchorDirective extends PopoverDirectivesBase {
    constructor(hostEl, ngZone, popupService, renderer, popoverService) {
        super(ngZone, popupService, renderer);
        this.hostEl = hostEl;
        this.ngZone = ngZone;
        this.popupService = popupService;
        this.renderer = renderer;
        this.popoverService = popoverService;
        this.mouseenterHandler = () => {
            this.controlVisibility(this.hostEl.nativeElement, true);
        };
        this.mouseleaveHandler = () => {
            if (this.isPrevented(this.hostEl.nativeElement, false)) {
                return;
            }
            if (!this._hideSub) {
                this._hideSub = this.popoverService.hidePopover.subscribe((val) => {
                    const [isPopoverHovered, isAnchorHovered] = val;
                    if (!isPopoverHovered && !isAnchorHovered) {
                        this.hide();
                    }
                });
            }
        };
        this.focusHandler = () => {
            this.controlVisibility(this.hostEl.nativeElement, true);
        };
        this.blurHandler = (args) => {
            const event = args.domEvent;
            if (this.isPrevented(this.hostEl.nativeElement, false)) {
                return;
            }
            // from anchor to popup focus check
            const isFocusInside = !!closest(event.relatedTarget, (node) => node.classList && node.classList.contains('k-popover'));
            if (!isFocusInside) {
                this.hide();
            }
            if (!this._focusInsideSub) {
                // inside popup focus check
                this._focusInsideSub = this.popoverService.isFocusInsidePopover.pipe(filter(v => v !== null)).subscribe((val) => {
                    if (!val) {
                        this.hide();
                    }
                });
            }
        };
        this._popoverService = this.popoverService;
    }
    /**
     * Shows the Popover. [See example]({% slug programmaticcontrol_popover %})
     */
    show() {
        if (this.popupRef) {
            return;
        }
        this.ngZone.run(() => {
            this.openPopup(this.hostEl);
        });
        this.popupRef.popupAnchorViewportLeave
            .pipe(take(1))
            .subscribe(() => this.hide());
    }
    /**
     * Toggles the visibility of the Popover. [See example]({% slug programmaticcontrol_popover %})
     */
    toggle() {
        if (this.popupRef) {
            this.hide();
        }
        else {
            this.show();
        }
    }
    subscribeToEvents(arr) {
        this.subs.add(this.renderer.listen(this.hostEl.nativeElement, arr[0].name, () => {
            this.popoverService.emitAnchorState(true, this.hostEl.nativeElement);
            arr[0].handler();
        }));
        this.subs.add(this.renderer.listen(this.hostEl.nativeElement, arr[1].name, (e) => {
            this.popoverService.emitAnchorState(false, null);
            arr[1].handler({ domEvent: e });
        }));
    }
    subscribeClick() {
        if (this.disposeClickListener) {
            this.disposeClickListener();
        }
        this.disposeClickListener = this.renderer.listen(document, 'click', (e) => {
            this.onClick(e);
        });
    }
    /**
     * @hidden
     */
    onClick(event) {
        const isInsidePopup = !!closest(event.target, (node) => node.classList && node.classList.contains('k-popup'));
        let isAnchor = !!closest(event.target, (node) => node === this.hostEl.nativeElement);
        if (isInsidePopup || (this.popupRef && isAnchor)) {
            return;
        }
        if (isAnchor) {
            // on opening
            this.controlVisibility(this.hostEl.nativeElement, true);
        }
        else {
            // on closing
            this.controlVisibility(this.hostEl.nativeElement, false);
        }
    }
    controlVisibility(anchor, show) {
        if (this.isPrevented(anchor, show)) {
            return;
        }
        show ? this.show() : this.hide();
    }
};
PopoverAnchorDirective = __decorate([
    Directive({
        selector: '[kendoPopoverAnchor]',
        exportAs: 'kendoPopoverAnchor',
        providers: [PopoverService]
    }),
    __metadata("design:paramtypes", [ElementRef,
        NgZone,
        PopupService,
        Renderer2,
        PopoverService])
], PopoverAnchorDirective);

var LocalizedMessagesDirective_1;
/**
 * @hidden
 */
let LocalizedMessagesDirective = LocalizedMessagesDirective_1 = class LocalizedMessagesDirective extends ComponentMessages {
    constructor(service) {
        super();
        this.service = service;
    }
};
__decorate([
    Input(),
    __metadata("design:type", String)
], LocalizedMessagesDirective.prototype, "closeTitle", void 0);
LocalizedMessagesDirective = LocalizedMessagesDirective_1 = __decorate([
    Directive({
        providers: [
            {
                provide: ComponentMessages,
                useExisting: forwardRef(() => LocalizedMessagesDirective_1)
            }
        ],
        selector: `[kendoTooltipLocalizedMessages]`
    }),
    __metadata("design:paramtypes", [LocalizationService])
], LocalizedMessagesDirective);

const COMPONENT_DIRECTIVES = [TooltipDirective, TooltipContentComponent, LocalizedMessagesDirective];
const COMPONENT_MODULES = [PopupModule];
/**
 * Represents the [NgModule]({{ site.data.urls.angular['ngmoduleapi'] }})
 * definition for the Tooltip component.
 *
 * The package exports:
 * - `KendoTooltipDirective`&mdash;The Tooltip directive class.
 *
 * @example
 *
 * ```ts-no-run
 * // Import the Tooltip module
 * import { TooltipModule } from '@progress/kendo-angular-tooltip';
 *
 * // The browser platform with a compiler
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 *
 * import { NgModule } from '@angular/core';
 *
 * // Import the app component
 * _@NgModule{{
 *    declarations: [AppComponent], // declare app component
 *    imports:      [BrowserModule, TooltipModule], // import TooltipModule module
 *    bootstrap:    [AppComponent]
 * }}
 * export class AppModule {}
 *
 * // Compile and launch the module
 * platformBrowserDynamic().bootstrapModule(AppModule);
 * ```
 */
let TooltipModule = class TooltipModule {
};
TooltipModule = __decorate([
    NgModule({
        declarations: [COMPONENT_DIRECTIVES],
        entryComponents: [TooltipContentComponent],
        imports: [CommonModule, ...COMPONENT_MODULES],
        exports: [COMPONENT_DIRECTIVES]
    })
], TooltipModule);

const DIRECTIVES = [
    PopoverActionsTemplateDirective,
    PopoverBodyTemplateDirective,
    PopoverTitleTemplateDirective,
    PopoverAnchorDirective,
    PopoverContainerDirective
];
/**
 * Represents the [NgModule]({{ site.data.urls.angular['ngmoduleapi'] }})
 * definition for the Popover component.
 *
 * @example
 *
 * ```ts-no-run
 * import { PopoverModule } from '@progress/kendo-angular-tooltip';
 *
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 * import { NgModule } from '@angular/core';
 *
 * import { AppComponent } from './app.component';
 *
 * _@NgModule({
 *     declarations: [AppComponent],
 *     imports:      [BrowserModule, PopoverModule],
 *     bootstrap:    [AppComponent]
 * })
 * export class AppModule {}
 *
 * platformBrowserDynamic().bootstrapModule(AppModule);
 *
 * ```
 */
let PopoverModule = class PopoverModule {
};
PopoverModule = __decorate([
    NgModule({
        declarations: [
            ...DIRECTIVES,
            PopoverComponent
        ],
        entryComponents: [PopoverComponent],
        exports: [...DIRECTIVES, PopoverComponent],
        imports: [
            CommonModule,
            PopupModule
        ]
    })
], PopoverModule);

/**
 * Represents the [NgModule]({{ site.data.urls.angular['ngmoduleapi'] }})
 * definition for the Tooltips components.
 *
 * @example
 *
 * ```ts-no-run
 * import { TooltipsModule } from '@progress/kendo-angular-tooltip';
 *
 * // The browser platform with a compiler
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 *
 * import { NgModule } from '@angular/core';
 *
 * // Import the app component
 * _@NgModule{{
 *    declarations: [AppComponent], // declare app component
 *    imports:      [BrowserModule, TooltipsModule], // import TooltipsModule module
 *    bootstrap:    [AppComponent]
 * }}
 * export class AppModule {}
 *
 * // Compile and launch the module
 * platformBrowserDynamic().bootstrapModule(AppModule);
 * ```
 */
let TooltipsModule = class TooltipsModule {
};
TooltipsModule = __decorate([
    NgModule({
        exports: [TooltipModule, PopoverModule]
    })
], TooltipsModule);

// Tooltip

/**
 * Generated bundle index. Do not edit.
 */

export { LocalizedMessagesDirective, PopoverDirectivesBase, PopoverService, TooltipContentComponent, TooltipDirective, TooltipSettings, TOOLTIP_SETTINGS, PopoverComponent, PopoverContainerDirective, PopoverAnchorDirective, PopoverTitleTemplateDirective, PopoverBodyTemplateDirective, PopoverActionsTemplateDirective, PopoverShowEvent, PopoverShownEvent, PopoverHideEvent, PopoverHiddenEvent, TooltipModule, PopoverModule, TooltipsModule };
